// src/store/threadStore.ts
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Thread, SendMessageRequest, ApiError } from "../types";
import { apiServices } from "../services/api";

export interface ThreadState {
  threads: Thread[];
  currentThread: Thread | null;
  loading: "idle" | "loading" | "success" | "error";
  error: ApiError | null;
}

export interface ThreadActions {
  fetchThreads: () => Promise<void>;
  fetchThreadById: (threadId: string) => Promise<void>;
  sendMessage: (request: SendMessageRequest) => Promise<void>;
  setCurrentThread: (thread: Thread | null) => void;
  setLoading: (state: ThreadState["loading"]) => void;
  setError: (error: ApiError | null) => void;
  clearThreads: () => void;
}

export interface ThreadStore extends ThreadState, ThreadActions {}

const sortThreadsByDate = (threads: Thread[]): Thread[] => {
  return [...threads].sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });
};

export const useThreadStore = create<ThreadStore>()(
  devtools(
    persist(
      (set, get) => ({
        // Initial state
        threads: [],
        currentThread: null,
        loading: "idle",
        error: null,

        // Actions
        fetchThreads: async () => {
          try {
            set({ loading: "loading", error: null });
            const response = await apiServices.threads.getThreads();
            if (response.error) {
              set({
                error: response.data.error as ApiError,
                loading: "error",
              });
              return;
            }

            const sortedThreads = sortThreadsByDate(
              response.data as unknown as Thread[]
            );
            set({
              threads: sortedThreads,
              loading: "success",
            });
          } catch (error) {
            set({
              error: error as ApiError,
              loading: "error",
            });
          }
        },

        fetchThreadById: async (threadId: string) => {
          try {
            set({ loading: "loading", error: null });
            const response = await apiServices.threads.getThreadById(threadId);
            set({
              currentThread: response.data as Thread | any,
              loading: "success",
            });
          } catch (error) {
            set({
              error: error as ApiError,
              loading: "error",
            });
          }
        },

        sendMessage: async (request: SendMessageRequest) => {
          try {
            set({ loading: "loading", error: null });
            const response = await apiServices.threads.sendMessage(request);

            // Update current thread with new message
            set({
              currentThread: response.data.thread,
              loading: "success",
            });

            // Update thread in threads list and maintain sort order
            const { threads } = get();
            const updatedThreads = threads.map((thread) =>
              thread.id === response.data.thread.id
                ? response.data.thread
                : thread
            );
            const sortedThreads = sortThreadsByDate(updatedThreads);
            set({ threads: sortedThreads });
          } catch (error) {
            set({
              error: error as ApiError,
              loading: "error",
            });
          }
        },

        setCurrentThread: (thread: Thread | null) =>
          set({ currentThread: thread }),

        setLoading: (state: ThreadState["loading"]) => set({ loading: state }),

        setError: (error: ApiError | null) =>
          set({ error, loading: error ? "error" : "idle" }),

        clearThreads: () =>
          set({
            threads: [],
            currentThread: null,
            loading: "idle",
            error: null,
          }),
      }),
      {
        name: "thread-storage",
        partialize: (state) => ({
          threads: state.threads,
          currentThread: state.currentThread,
        }),
      }
    )
  )
);
