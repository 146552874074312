// src/services/api/user.ts
import { BaseApiService } from "./base";
import { SignInRequest, ApiResponse, User, UserResponse, SignInResponse } from "../../types";

export class UserApiService extends BaseApiService {
  /**
   * Sign in user with email and access token
   */
  async signIn(request: SignInRequest): Promise<ApiResponse<SignInResponse>> {
    const response = await this.api.post<any, ApiResponse<SignInResponse>>(
      "/sign-in",
      request
    );

    if (response.data?.jwtToken) {
      // Set the auth token both as cookie and header
      this.setAuthToken(response.data.jwtToken);
    }

    return response;
  }

  /**
   * Sign out current user
   */
  signOut(): void {
    this.removeAuthToken();
  }

  /**
   * Get current user's settings
   */
  async getSettings(): Promise<ApiResponse<UserResponse>> {
    return this.api.get<any, ApiResponse<UserResponse>>("/settings");
  }

  /**
   * Update user settings
   */
  async updateSettings(
    settings: Partial<User["settings"]>
  ): Promise<ApiResponse<UserResponse>> {
    return this.api.put<any, ApiResponse<UserResponse>>("/settings", settings);
  }
}

// Create a singleton instance
export const userApiService = new UserApiService();