// src/services/api/base.ts
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { ApiError } from "../../types";
import { getEnvVar } from "../../utils/validateEnv";
import Cookies from "js-cookie";
import { useUserStore } from "../../store/userStore";

export class BaseApiService {
  protected api: AxiosInstance;

  constructor() {
    const apiUrl = getEnvVar("REACT_APP_API_URL");
    const apiVersion = getEnvVar("REACT_APP_API_VERSION");

    this.api = axios.create({
      baseURL: `${apiUrl}/${apiVersion}`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    // Add request interceptor to handle auth
    this.api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      const token = Cookies.get("jwt");
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    // Add response interceptor to handle API response format
    this.api.interceptors.response.use(
      (response: AxiosResponse) => {
        const jwtToken = response.headers["set-cookie"]?.find(
          (cookie: string) => cookie.startsWith("jwt=")
        );
        if (jwtToken) {
          const tokenValue = jwtToken.split(";")[0].split("=")[1];
          Cookies.set("jwt", tokenValue, {
            path: "/",
            secure: true,
            sameSite: "strict",
          });
        }
        return response.data;
      },
      (error: any) => {
        // Handle 401 Unauthorized errors
        if (error.response?.status === 401) {
          // Check if the error is specifically for expired token
          const isTokenExpired =
            error.response?.data?.error === "TokenExpired" ||
            error.response?.data?.message
              ?.toLowerCase()
              .includes("refresh your token");

          if (isTokenExpired) {
            // Clear auth state from cookies and headers
            this.removeAuthToken();

            // Update user store
            const userStore = useUserStore.getState();
            userStore.logout();
            userStore.setError("Session expired. Please log in again.");

            // Redirect to login page
            window.location.href = "/login";

            // Return a rejected promise with the error
            return Promise.reject({
              message: "Session expired. Please log in again.",
              code: "TOKEN_EXPIRED",
              status: 401,
            });
          }
        }

        // Handle other errors as before
        const apiError: ApiError = {
          message:
            error.response?.data?.message || "An unexpected error occurred",
          code: error.response?.data?.code || "UNKNOWN_ERROR",
          status: error.response?.status || 500,
        };
        return Promise.reject(apiError);
      }
    );
  }

  protected setAuthToken(token: string) {
    // Set both cookie and Authorization header
    Cookies.set("jwt", token, {
      path: "/",
      secure: true,
      sameSite: "strict",
    });
    this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    // Update token in user store
    useUserStore.getState().setJwtToken(token);
  }

  protected removeAuthToken() {
    // Clear cookie and header
    Cookies.remove("jwt");
    delete this.api.defaults.headers.common["Authorization"];

    // Clear user store
    const userStore = useUserStore.getState();
    userStore.logout();
  }
}
