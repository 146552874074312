import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Button,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { Settings, LogOut, MessageCircle, Plus } from "lucide-react";
import { useUserStore } from "../../store/userStore";
import { useThreadStore } from "../../store/threadStore";
import { useMessageStore } from "../../store/messageStore";
import { apiServices } from "../../services/api";
import { formatDistanceToNow } from "date-fns";
import { Thread } from "../../types/thread";
import { toast } from "react-toastify";

interface HeaderProps {
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { threadId } = useParams();
  const logout = useUserStore((state) => state.logout);
  const userId = useUserStore((state) => state.user?._id);
  const {
    threads = [],
    fetchThreads,
    setCurrentThread,
    loading,
  } = useThreadStore();
  const { clearMessages } = useMessageStore();

  const [profileAnchorEl, setProfileAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const [chatAnchorEl, setChatAnchorEl] = React.useState<HTMLElement | null>(
    null
  );

  // Only fetch threads when menu is opened and we don't have them yet
  const handleChatMenu = async (event: React.MouseEvent<HTMLElement>) => {
    setChatAnchorEl(event.currentTarget);
    await fetchThreads();
  };

  // Filter threads by current user
  const userThreads = threads.filter((thread) => {
    return thread.userId === userId;
  });

  const handleChatSelect = (thread: Thread) => {
    handleCloseChat();
    setCurrentThread(thread);
    navigate(`/app/chat/${thread.id}`);
  };

  const handleLogoClick = () => {
    navigate("/app/chat");
  };

  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleCloseProfile = () => setProfileAnchorEl(null);
  const handleCloseChat = () => setChatAnchorEl(null);

  const handleLogout = () => {
    handleCloseProfile();
    apiServices.user.signOut();
    logout();
    navigate("/login");
  };

  const handleSettings = () => {
    handleCloseProfile();
    navigate("/app/settings");
  };

  const handleNewChat = () => {
    handleCloseChat();
    setCurrentThread(null);
    navigate("/app/chat/new");
    clearMessages();
    setTimeout(() => {
      document.querySelector<HTMLTextAreaElement>(".chat-input")?.focus();
      toast.info("Send your first message to start a new thread", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 100);
  };

  const getLastMessageTime = (thread: Thread) => {
    if (!thread?.messages?.length) {
      return "No messages";
    }
    return formatDistanceToNow(new Date(thread.updatedAt), { addSuffix: true });
  };

  const truncateTitle = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  };

  return (
    <AppBar position="fixed" className="bg-white shadow-md">
      <Toolbar className="flex justify-between items-center">
        <div
          className="flex items-center cursor-pointer"
          onClick={handleLogoClick}
        >
          {children}
        </div>

        <div className="flex items-center gap-4">
          <Button
            color="inherit"
            onClick={handleChatMenu}
            className="text-gray-700"
            startIcon={<MessageCircle size={20} />}
          >
            Threads
          </Button>

          <Menu
            anchorEl={chatAnchorEl}
            open={Boolean(chatAnchorEl)}
            onClose={handleCloseChat}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                style: {
                  maxHeight: "50vh",
                  overflow: "auto",
                },
              },
            }}
          >
            <MenuItem onClick={handleNewChat} className="text-blue-600">
              <Plus size={16} className="mr-2" />
              New Thread
            </MenuItem>

            {(() => {
              if (loading === "loading") {
                return (
                  <MenuItem
                    disabled
                    className="flex justify-center min-w-[240px]"
                  >
                    <CircularProgress size={24} />
                  </MenuItem>
                );
              } else if (!userThreads || userThreads.length === 0) {
                return (
                  <MenuItem disabled className="text-gray-500 min-w-[240px]">
                    <ListItemText
                      primary="No threads yet"
                      secondary="Start a new conversation"
                    />
                  </MenuItem>
                );
              } else {
                return userThreads.map((thread) => (
                  <MenuItem
                    key={thread.id}
                    onClick={() => handleChatSelect(thread)}
                    className={`min-w-[240px] ${
                      thread.id === threadId
                        ? "bg-blue-50 dark:bg-blue-900"
                        : ""
                    }`}
                    title={thread.title || "Untitled Thread"}
                  >
                    <ListItemText
                      primary={truncateTitle(
                        thread.title || "Untitled Thread",
                        50
                      )}
                      secondary={`Last updated ${getLastMessageTime(thread)}`}
                    />
                  </MenuItem>
                ));
              }
            })()}
          </Menu>

          <IconButton onClick={handleProfileMenu} className="ml-2">
            <Avatar className="bg-blue-600">JS</Avatar>
          </IconButton>

          <Menu
            anchorEl={profileAnchorEl}
            open={Boolean(profileAnchorEl)}
            onClose={handleCloseProfile}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleSettings} className="gap-2">
              <Settings size={16} />
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogout} className="gap-2 text-red-600">
              <LogOut size={16} />
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
